import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';

import { environment } from '@env';

import { AuthApiService, AuthService, StorageService } from '@core/services';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
  decodedToken: string | null | undefined;

  constructor(public authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // If we are calling to a protected API, add the JWT to the request
    if (!this.authService.isRefreshing()
      && (request.url.startsWith(environment.adminApiBaseUrl)
      || request.url.startsWith(environment.authApiBaseUrl)
      || request.url.startsWith(environment.documentApiBaseUrl)
      || request.url.startsWith(environment.storageApiBaseUrl))) {

      // Do not intercept the client_credentials token request, we need MSAL to handle that
      if (request.url.startsWith(environment.authApiBaseUrl)
        && request.url.endsWith('token')
        && request.body
        && request.body instanceof FormData) {
        const formData = request.body as FormData;
        if (formData.has('grant_type') && formData.get('grant_type') === 'client_credentials') {
          return next.handle(request);
        }
      }

      request = this.authService.authorize(request);

      return next.handle(request).pipe(catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.authService.refreshTokenAndAuthorize(request, next);
        } else {
          return throwError(() => error);
        }
      }));
    }

    // all other requests, just pass through
    return next.handle(request);
  }
}
