export class Document {
  _etag?: string;
  constructor(
    public id: string,
    public documentId: string,
    public documentType: string,
    public content: any,
    public name?: string,
    public language?: string,
    public workspaceId?: string,
    public isPrivate?: boolean,
    public owners?: string[],
    public lastModified?: string,
  ) { }
}

export const DocumentContentType = 'application/vnd.bsi.connect.document.v2+json';
