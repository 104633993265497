import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  readonly translationKeyMap = new Map([
    ['members.errors.invalidEmail',
      'Invalid email address'],
    ['members.errors.onlyBSIEmailForSuperadmin',
      'Only a member with a BSI email address can have a superadmin policy'],
    ['members.errors.onlyBSIEmailForAdmin',
      'Only a member with a BSI email address can have the platform admin policy'],
    ['members.errors.workspaceAdminLimitExceeded',
      'You do not have enough Admin policies available.'],
    ['members.errors.workspaceStandardLimitExceeded',
      'You do not have enough Standard policies available.']
  ]);
  constructor() { }

  translate(key: string | null, message: string | null = null): string {
    return key
      ? this.translationKeyMap.get(key) || message || key
      : message || key || 'General error';
  }
}
