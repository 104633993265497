import { Injectable } from '@angular/core';
import { MemberContent, SecurityEntity } from '@core/models';
import { RecordTypes } from '@shared/reference';
import { DocumentApiService } from './document-api.service';
import { Document } from '@core/models';
import { v4 as uuidv4 } from 'uuid';
import { AdminApiService } from './admin-api.service';

export interface MemberChange {
  id: string | undefined;
  name: string;
  email: string;
  workspacePolicy: string;
};

@Injectable({
  providedIn: 'root'
})
export class UserMemberService {

  constructor(
    private adminApiService: AdminApiService,
    private documentApiService: DocumentApiService) {
  }

  async updateMembersAndPersonsInWorkspace(
    workspaceId: string,
    memberChanges: MemberChange[]): Promise<MemberChange[]> {

    try {
      const [membersResponse, personRecordType, persons] = await Promise.all([
        await this.adminApiService.getMembers(),
        await this.documentApiService.getRecordType(RecordTypes.person, workspaceId),
        await this.documentApiService.getRecordsOfType(RecordTypes.person, workspaceId)
      ]);

      const members = membersResponse.entities;

      if (personRecordType) {
        // Update existing persons
        for (const memberChange of memberChanges) {
          const person = persons.find(p => p.id === memberChange.id);
          if (person) {
            // Update existing person
            person.content.fields['workspacePolicy'] = memberChange.workspacePolicy;
            await this.documentApiService.updateRecord(person.id, workspaceId, person);
          } else {
            // Create new person
            let member = members.find(m => m.id === memberChange.id);
            if (!member) {
              // Create member since it does not exit
              const memberContent: MemberContent = {
                objectId: '',
                name: memberChange.name,
                email: memberChange.email,
                isEnabled: true,
                hasPassword: false,
                isServiceMember: false,
                platformPolicy: 'standard'
              };
              member = await this.adminApiService.createMember(memberContent);
              if (member) {
                memberChange.id = member.id;
                members.push(member);
              }
            }
            if (member) {
              // Member is existing or now existing, create person in workspace
              const newPerson = await this.createPersonRecord(
                workspaceId,
                memberChange.workspacePolicy,
                member);
              if (newPerson) {
                persons.push(newPerson);
              }
            } else {
              throw new Error(`Unable to create member for ${memberChange.name}`);
            }
          }

          // Unlink persons that are not in the list
          for (const person of persons) {
            if (!memberChanges.find(m => m.id === person.id)) {
              person.content.fields['workspacePolicy'] = '';
              person.content.fields['memberId'] = null;
              await this.documentApiService.updateRecord(person.id, workspaceId, person);
            }
          }
        }
      } else {
        for (const memberChange of memberChanges) {
          let member = members.find(m => m.id === memberChange.id);
          if (!member) {
            // Create member since it does not exit
            const memberContent: MemberContent = {
              objectId: '',
              name: memberChange.name,
              email: memberChange.email,
              isEnabled: true,
              hasPassword: false,
              isServiceMember: false,
              platformPolicy: 'standard'
            };
            member = await this.adminApiService.createMember(memberContent);
            if (member) {
              memberChange.id = member.id;
              members.push(member);
            }
          }
        }
      }
      return memberChanges;
    } catch (e: any) {
      throw new Error('Error updating members and persons in workspace: '
        + (e instanceof Error ? e.message : e));
    }
  }

  async linkMemberToPerson(workspaceId: string,
    member: SecurityEntity<MemberContent>,
    workspacePolicy: string): Promise<void> {

    try {
      await this.documentApiService.getRecordType(RecordTypes.person, workspaceId);
    } catch {
      // Person records do not exist, skip linking
      return;
    }

    const persons = await this.documentApiService.getRecordsOfType(RecordTypes.person, workspaceId);
    let person = persons.find(p => p.content.fields['memberId'] === member.id);
    if (!person) {
      person = persons.find(p => p.content.fields['email'] === member.content.email);
    }

    if (person) {
      person.content.fields['state'] = 'active';
      person.content.fields['memberId'] = workspacePolicy ? member.id : null;
      person.content.fields['workspacePolicy'] = workspacePolicy;
      person.content.fields['displayName'] = member.content.name;
      await this.documentApiService.updateRecord(person.id, workspaceId, person);
      return;
    } else {
      // Create and activate a new person
      await this.createPersonRecord(workspaceId, workspacePolicy, member);
      return;
    }
  }

  async createPersonRecord(
    workspaceId: string,
    workspacePolicy: string,
    member: SecurityEntity<MemberContent>): Promise<Document> {

    const recordId = uuidv4();
    const newPerson: Document = {
      id: recordId,
      documentId: recordId,
      documentType: 'record',
      content: {
        recordType: RecordTypes.person,
        fields: {
          subType: RecordTypes.person,
          state: 'active',
          email: member.content.email,
          fullName: member.content.name,
          displayName: member.content.name,
          workspacePolicy: workspacePolicy,
          memberId: member.id
        },
        links: {}
      }
    }

    return await this.documentApiService.createRecord(newPerson, workspaceId);
  }

  async unLinkMemberFromPerson(workspaceId: string, memberId: string): Promise<void> {
    try {
      await this.documentApiService.getRecordType(RecordTypes.person, workspaceId);
    } catch {
      // Person records do not exist, skip unlinking
      return;
    }

    const persons = await this.documentApiService.getRecordsOfType(
      RecordTypes.person,
      workspaceId);
    if (persons.length > 0) {
      let person = persons.find(p => p.content.fields['memberId'] === memberId);
      if (person) {
        person.content.fields['memberId'] = null;
        person.content.fields['workspacePolicy'] = '';
        await this.documentApiService.updateRecord(person.id, workspaceId, person);
      }
    }
  }

  async createDefaultLocations(workspaceId: string, workspaceTemplateId: string): Promise<Boolean> {
    if (workspaceTemplateId === 'automation' || workspaceTemplateId === 'smartAuditV2') {

      const createCalls: Promise<any>[] = [];

      const locations = ['London', 'Mumbai', 'Hong Kong', 'Johor', 'Haarlemweg', 'Singapore'];

      for (const location of locations) {
        const recordId = uuidv4();
        const locationRecord: Document = {
          id: recordId,
          documentId: recordId,
          documentType: 'record',
          content: {
            recordType: RecordTypes.location,
            fields: {
              subType: RecordTypes.location,
              state: 'draft',
              name: location,
              description: '-',
              postalCode: '-'
            },
            links: {}
          }
        };
        createCalls.push(this.documentApiService.createRecord(locationRecord, workspaceId))
      }

      try {
        await Promise.all(createCalls);
        console.log('default locations created');
        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    }
    return true;
  }
}
