import { Injectable } from '@angular/core';
import { DocumentTypes } from '@shared/reference';
import { Document } from '@core/models';

import Ajv from 'ajv';
import addFormats from 'ajv-formats';
import { Library, LibraryService } from './library.service';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';


@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  private validateRecordType: any;
  private validateTranslation: any;
  private validateReferenceData: any;
  private validateDashboard: any;
  private validateTermsOfUse: any;
  private validateEmailTemplate: any;

  constructor(private library: LibraryService) {
    const ajv = new Ajv();
    addFormats(ajv);

    this.library.getItem$(Library.RecordTypeContentSchema)
      .subscribe(schema => this.validateRecordType = ajv.compile(schema));
    this.library.getItem$(Library.TranslationContentSchema)
      .subscribe(schema => this.validateTranslation = ajv.compile(schema));
    this.library.getItem$(Library.ReferenceDataContentSchema)
      .subscribe(schema => this.validateReferenceData = ajv.compile(schema));
    this.library.getItem$(Library.DashboardContentSchema)
      .subscribe(schema => this.validateDashboard = ajv.compile(schema));
    this.library.getItem$(Library.TermsOfUseContentSchema)
      .subscribe(schema => this.validateTermsOfUse = ajv.compile(schema));
    this.library.getItem$(Library.EmailTemplateContentSchema)
      .subscribe(schema => this.validateEmailTemplate = ajv.compile(schema));
  }

  validationErrors(document: Document): string[] | null {

    if (document.documentType === DocumentTypes.workspaceData
      || document.documentType === DocumentTypes.userData) {
      return null;
    }
    const type = document.documentType;
    const validate = type === DocumentTypes.recordType
    ? this.validateRecordType
    : type === DocumentTypes.referenceData
      ? this.validateReferenceData
      : type === DocumentTypes.translation
        ? this.validateTranslation
        : type === DocumentTypes.dashboard
          ? this.validateDashboard
          : type === DocumentTypes.termsOfUse
            ? this.validateTermsOfUse
            : type === DocumentTypes.emailTemplate
              ? this.validateEmailTemplate
              : null;

    if (validate) {
      if (validate(document.content)) {
        return null;
      }
      console.log(validate.errors);
      return validate.errors!.map(
        (e: any) => `${e.instancePath}: ${e.message.charAt(0).toUpperCase()}${e.message.slice(1)}`
      );
    }
    return null;
  }

  get uriNamePattern(): RegExp {
    return /^[a-z][-a-z0-9]+[a-z0-9]$/;
  }

  forbiddenValueValidator(forbiddenNames: string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const forbidden = forbiddenNames.includes(control.value);
      return forbidden ? { forbiddenName: { value: control.value } } : null;
    };
  }

  getUriNamePatternErrorMessage(value: string): string {
    if (value.length < 3) {
      return 'Must be at least 3 characters';
    }
    if (value.length > 63) {
      return 'Must be at most 63 characters';
    }
    if (value.startsWith('-')) {
      return 'Cannot start with a hyphen';
    }
    if (value.endsWith('-')) {
      return 'Cannot end with a hyphen';
    }
    if (value.match(/^[0-9]/)) {
      return 'Cannot start with a number';
    }
    return 'Must be lowercase, contain only letters, numbers, and hyphens';
  }

  isLevelPolicy(policy: string): boolean {
    return policy === 'superadmin'
      || policy === 'admin'
      || policy === 'standard'
      || policy === 'readonly'
      || policy === '';

  }

  isValidEmail(email: string) {
    let emailRegex = /^[^\s@(),.][^\s@()]*@[^\s@()]+\.[^\s@()]+$/;
    return emailRegex.test(email);
  }
}
