import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { firstValueFrom, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(public dialog: MatDialog) { }

  open<TDialog, TData>(
    component: ComponentType<TDialog>,
    config?: MatDialogConfig<TData> | undefined): MatDialogRef<TDialog, any> {

    return this.dialog.open(component, config);
  }

  show$<TDialog, TData, TResponse>(
    component: ComponentType<TDialog>,
    config?: MatDialogConfig<TData> | undefined): Observable<TResponse | undefined> {

    const ref = this.dialog.open<TDialog, TData, TResponse>(component, config);
    return ref.afterClosed();
  }

  async show<TDialog, TData, TResponse>(
    component: ComponentType<TDialog>,
    config?: MatDialogConfig<TData> | undefined): Promise<TResponse | undefined> {

    const ref = this.dialog.open<TDialog, TData, TResponse>(component, config);
    return await firstValueFrom(ref.afterClosed());
  }
}
