import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, firstValueFrom, from, mergeMap, of } from 'rxjs';

import { environment } from '@env';

import {
  MemberContent, SecurityEntitiesResponse, SecurityEntity, ServiceVersion, WorkspaceContent
} from '@core/models';

@Injectable({
  providedIn: 'root'
})
export class AdminApiService {
  private readonly postOptions = {
    observe: 'response' as 'response',
    responseType: 'json' as 'json',
  };

  constructor(private http: HttpClient) { }

  async getVersion(): Promise<ServiceVersion> {
    return await firstValueFrom(
      this.http.get<ServiceVersion>(environment.adminApiBaseUrl + '/health/version'));
  }

  //
  // Workspaces
  //

  createWorkspace$(content: WorkspaceContent): Observable<SecurityEntity<WorkspaceContent>> {
    return from(this.createWorkspace(content));
  }

  async createWorkspace(content: WorkspaceContent): Promise<SecurityEntity<WorkspaceContent>> {
    return await firstValueFrom(
      this.http.post<WorkspaceContent>(
        environment.adminApiBaseUrl + '/workspaces',
        content,
        this.postOptions)
        .pipe(
          mergeMap((response: HttpResponse<WorkspaceContent>, _) => {
            const id = response.headers.get('Location')?.split('/').pop();
            const content = response.body as WorkspaceContent;
            return of({ id, content } as SecurityEntity<WorkspaceContent>);
          })
        ));
  }

  async updateWorkspace(content: WorkspaceContent, id: string): Promise<Object> {
    return await firstValueFrom(
      this.http.put(environment.adminApiBaseUrl + `/workspaces/${id}`, content));
  }

  getWorkspaces$(): Observable<SecurityEntitiesResponse<WorkspaceContent>> {
    return from(this.getWorkspaces());
  }

  async getWorkspaces(): Promise<SecurityEntitiesResponse<WorkspaceContent>> {
    return await firstValueFrom(
      this.http.get<SecurityEntitiesResponse<WorkspaceContent>>(
        environment.adminApiBaseUrl + '/workspaces'));
  }

  getWorkspace$(id: string): Observable<WorkspaceContent> {
    return from(this.getWorkspace(id));
  }

  async getWorkspace(id: string): Promise<WorkspaceContent> {
    return await firstValueFrom(
      this.http.get<WorkspaceContent>(environment.adminApiBaseUrl + `/workspaces/${id}`));
  }

  async patchWorkspace(patch: any, id: string): Promise<WorkspaceContent> {
    return await firstValueFrom(
      this.http.patch<WorkspaceContent>(
        environment.adminApiBaseUrl + `/workspaces/${id}`,
        patch));
  }

  //
  // Members
  //

  async createMember(content: MemberContent): Promise<SecurityEntity<MemberContent>> {
    return await firstValueFrom(
      this.http.post<MemberContent>(
        environment.adminApiBaseUrl + '/members',
        content,
        this.postOptions).pipe(
          mergeMap((response: HttpResponse<MemberContent>, _) => {
            const id = response.headers.get('Location')?.split('/').pop();
            const content = response.body as MemberContent;
            return of({ id, content } as SecurityEntity<MemberContent>);
          }
          )
        ));
  }

  async updateMember(content: MemberContent, id: string): Promise<MemberContent> {
    return await firstValueFrom(
      this.http.put<MemberContent>(environment.adminApiBaseUrl + `/members/${id}`, content));
  }

  async getMembers(): Promise<SecurityEntitiesResponse<MemberContent>> {
    return await firstValueFrom(
      this.http.get<SecurityEntitiesResponse<MemberContent>>(
        environment.adminApiBaseUrl + '/members'));
  }

  async getMember(id: string): Promise<MemberContent> {
    return await firstValueFrom(
      this.http.get<MemberContent>(environment.adminApiBaseUrl + `/members/${id}`));
  }

  async patchMember(patch: any, id: string): Promise<MemberContent> {
    return await firstValueFrom(
      this.http.patch<MemberContent>(
        environment.adminApiBaseUrl + `/members/${id}`,
        patch));
  }
}
