import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, from, Observable } from 'rxjs';

import { environment } from '@env';
import { Document, DocumentContentType, ServiceVersion } from '../models';

@Injectable({
  providedIn: 'root'
})
export class DocumentApiService {

  constructor(private http: HttpClient) { }

  getVersion(): Observable<ServiceVersion> {
    return this.http.get<ServiceVersion>(environment.documentApiBaseUrl + '/health/version');
  }

  //
  // Record Types
  //

  createRecordType$(recordType: Document, workSpaceId: string): Observable<Document> {
    return from(this.createRecordType(recordType, workSpaceId));
  }

  async createRecordType(recordType: Document, workSpaceId: string): Promise<Document> {
    const headers = this.getStandardHeaders();

    return firstValueFrom(
        this.http.post<Document>(
          environment.documentApiBaseUrl + `/${workSpaceId}/recordTypes`,
          recordType,
          { headers: headers }));
  }

  deleteRecordType$(
    recordType: Document,
    workspaceId: string): Observable<Document> {
    const headers = this.getStandardHeaders()
      .set('If-Match', recordType._etag ?? '');

    return this.http.delete<Document>(
      environment.documentApiBaseUrl + `/${workspaceId}/recordTypes/${recordType.name}`,
      { headers: headers });
  }

  async deleteRecordType(
    recordType: Document,
    workspaceId: string): Promise<Document> {
    const headers = this.getStandardHeaders()
      .set('If-Match', recordType._etag ?? '');

    return firstValueFrom(
      this.http.delete<Document>(
        environment.documentApiBaseUrl + `/${workspaceId}/recordTypes/${recordType.name}`,
        { headers: headers }));
  }

  getAllRecordTypes$(workspaceId: string): Observable<Document[]> {
    return from(this.getAllRecordTypes(workspaceId));
  }

  async getAllRecordTypes(workspaceId: string): Promise<Document[]> {
    return firstValueFrom(
      this.http.get<Document[]>(
        environment.documentApiBaseUrl + `/${workspaceId}/recordTypes`,
        { headers: this.getStandardHeaders() }));
  }

  getRecordType$(recordTypeName: string, workspaceId: string): Observable<Document> {
    return this.http.get<Document>(
      environment.documentApiBaseUrl + `/${workspaceId}/recordTypes/${recordTypeName}`,
      { headers: this.getStandardHeaders() });
  }

  async getRecordType(recordTypeName: string, workspaceId: string): Promise<Document> {
    return firstValueFrom(
      this.http.get<Document>(
        environment.documentApiBaseUrl + `/${workspaceId}/recordTypes/${recordTypeName}`,
        { headers: this.getStandardHeaders() }));
  }

  updateRecordType$(
    document: Document,
    workspaceId: string): Observable<Document> {

    return this.http.put<Document>(
      environment.documentApiBaseUrl + `/${workspaceId}/recordTypes/${document.name}`,
      document,
      { headers: this.getStandardHeaders() });
  }

  async updateRecordType(
    document: Document,
    workspaceId: string): Promise<Document> {

    return await firstValueFrom(
      this.http.put<Document>(
        environment.documentApiBaseUrl + `/${workspaceId}/recordTypes/${document.name}`,
        document,
        { headers: this.getStandardHeaders() }));
  }

  //
  // Documents
  //

  createDocument$(document: Document, workspaceId: string): Observable<Document> {
    return from(this.createDocument(document, workspaceId));
  }

  async createDocument(document: Document, workspaceId: string): Promise<Document> {
    return firstValueFrom(
      this.http.post<Document>(
        environment.documentApiBaseUrl + `/${workspaceId}/documents/`, document,
        { headers: this.getStandardHeaders() }));
  }

  deleteDocument$(document: Document, workspaceId: string): Observable<Document> {
    const headers = this.getStandardHeaders()
      .set('If-Match', document._etag ?? '');

    return this.http.delete<Document>(
      environment.documentApiBaseUrl + `/${workspaceId}/documents/${document.documentId}`,
      { headers: headers });
  }

  async deleteDocument(document: Document, workspaceId: string): Promise<Document> {
    const headers = this.getStandardHeaders()
      .set('If-Match', document._etag ?? '');

    return firstValueFrom(
      this.http.delete<Document>(
        environment.documentApiBaseUrl + `/${workspaceId}/documents/${document.documentId}`,
        { headers: headers }));
  }

  getAllDocuments$(workspaceId: string): Observable<Document[]> {
    return from(this.getAllDocuments(workspaceId));
  }

  async getAllDocuments(workspaceId: string): Promise<Document[]> {
    return firstValueFrom(
      this.http.get<Document[]>(
        environment.documentApiBaseUrl + `/${workspaceId}/documents`,
        { headers: this.getStandardHeaders() }));
  }

  getDocument$(id: string, workspaceId: string): Observable<Document> {
    return this.http.get<Document>(
      environment.documentApiBaseUrl + `/${workspaceId}/documents/${id}`,
      { headers: this.getStandardHeaders() });
  }

  async getDocument(id: string, workspaceId: string): Promise<Document> {
    return firstValueFrom(
      this.http.get<Document>(
        environment.documentApiBaseUrl + `/${workspaceId}/documents/${id}`,
        { headers: this.getStandardHeaders() }));
  }

  updateDocument$(document: Document, workspaceId: string): Observable<Document> {
    return this.http.put<Document>(
      environment.documentApiBaseUrl + `/${workspaceId}/documents/${document.documentId}`,
      document,
      { headers: this.getStandardHeaders() });
  }

  async updateDocument(document: Document, workspaceId: string): Promise<Document> {
    return await firstValueFrom(
      this.http.put<Document>(
        environment.documentApiBaseUrl + `/${workspaceId}/documents/${document.documentId}`,
        document,
        { headers: this.getStandardHeaders() }));
  }

  //
  // Records
  //

  createRecord$(record: Document, workspaceId: string): Observable<Document> {
    return from(this.createRecord(record, workspaceId));
  }

  async createRecord(record: Document, workspaceId: string): Promise<Document> {
    return firstValueFrom(
      this.http.post<Document>(
        environment.documentApiBaseUrl
          + `/${workspaceId}/records/${record.content.recordType}`,
        record,
        { headers: this.getStandardHeaders() }));
  }

  deleteRecord(record: Document, workspaceId: string): Observable<Document> {
    const headers = this.getStandardHeaders()
      .set('If-Match', record._etag ?? '');

    return this.http.delete<Document>(
      environment.documentApiBaseUrl
        + `/${workspaceId}/records/${record.content.recordType}/${record.id}`,
      { headers: headers });
  }

  getRecordsOfType$(recordTypeName: string, workspaceId: string): Observable<Document[]> {
    return this.http.get<Document[]>(
      environment.documentApiBaseUrl
        + `/${workspaceId}/records/${recordTypeName}`,
      { headers: this.getStandardHeaders() });
  }

  async getRecordsOfType(recordTypeName: string, workspaceId: string): Promise<Document[]> {
    return await firstValueFrom(this.http.get<Document[]>(
      environment.documentApiBaseUrl
        + `/${workspaceId}/records/${recordTypeName}`,
      { headers: this.getStandardHeaders() }));
  }

  updateRecord$(id: string, workspaceId: string, record: Document): Observable<Document> {
    return this.http.put<Document>(
      environment.documentApiBaseUrl
        + `/${workspaceId}/records/${record.content.recordType}/${id}`,
      record,
      { headers: this.getStandardHeaders() });
  }

  async updateRecord(id: string, workspaceId: string, record: Document): Promise<Document> {
    return await firstValueFrom(this.http.put<Document>(
      environment.documentApiBaseUrl
        + `/${workspaceId}/records/${record.content.recordType}/${id}`,
      record,
      { headers: this.getStandardHeaders() }));
  }

  //
  // Reference Data
  //

  getReferenceData(workspaceId: string): Observable<Document[]> {
    return this.http.get<Document[]>(
      environment.documentApiBaseUrl + `/${workspaceId}/documents?documentType=referenceData`,
      { headers: this.getStandardHeaders() });
  }


  //
  // Translation
  //

  createTranslation(translation: Document, workspaceId: string): Observable<Document> {
    return this.http.post<Document>(
      environment.documentApiBaseUrl + `/${workspaceId}/documents/`,
      translation,
      { headers: this.getStandardHeaders() });
  }

  //
  // Workspace Data
  //

  getWorkspaceData$(workspaceId: string): Observable<Document> {
    return this.http.get<Document>(
      environment.documentApiBaseUrl + `/${workspaceId}/workspaceData`,
      { headers: this.getStandardHeaders() });
  }

  async getWorkspaceData(workspaceId: string): Promise<Document> {
    return await firstValueFrom(
        this.http.get<Document>(
        environment.documentApiBaseUrl + `/${workspaceId}/workspaceData`,
        { headers: this.getStandardHeaders() }));
  }

  private getStandardHeaders(): HttpHeaders {
    return new HttpHeaders()
      .set('Content-Type', DocumentContentType)
      .set('Accept', DocumentContentType);
  }
}
